import React from "react";
import "./App.css";
import { useRive } from "@rive-app/react-canvas";
import { Grid } from "@mui/material";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAkbtYZNL2Fgwz6EbK2Yesqustt8A566Lo",
  authDomain: "idios-fd0c8.firebaseapp.com",
  projectId: "idios-fd0c8",
  storageBucket: "idios-fd0c8.appspot.com",
  messagingSenderId: "282269893183",
  appId: "1:282269893183:web:9b6a2f3244e84f8e9aee8a",
  measurementId: "G-GW7K4NXHB9"
};

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  
  console.log(app);
  console.log(analytics);

  const { RiveComponent } = useRive({
    src: "/assets/idios_loading.riv",
    stateMachines: "Idle",
    artboard: "New Artboard",
    autoplay: true
  });
  return (
    <div className="App">
      <Grid
        container
        direction="column"
        align-items="center"
        justify-content="center"
        className="root"
      >
        <RiveComponent className="rive" />
      </Grid>
    </div>
  );
}

export default App;
